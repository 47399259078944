<template>
  <div class="groups">
    <div class="single-group" v-for="s in 2" :key="s.id">
      <div class="groups-row group-header">
        <skelet-rectangle :width="9999999" :height="17"/>
      </div>
      <div class="groups-row group-teams" v-for="i in 10" :key="i.id">
        <skelet-rectangle :width="9999999" :height="17"/>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletRectangle from "@/views/sceleton/components/rectangle";

export default {
  name: "groups-skeleton",
  components: {SkeletRectangle}
}
</script>

<style scoped lang="scss">
.groups {
  .single-group {
    .group-header {
      border-bottom: none;
      margin-bottom: 0;
      padding-top: 16px;
      padding-bottom: 8px;
    }
  }
}
</style>