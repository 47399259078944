import { render, staticRenderFns } from "./NbaGroupView.vue?vue&type=template&id=d1194aac&scoped=true&"
import script from "./NbaGroupView.vue?vue&type=script&lang=js&"
export * from "./NbaGroupView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1194aac",
  null
  
)

export default component.exports